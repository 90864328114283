import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { userActionStore } from '@/store';
import { Chart } from 'highcharts-vue';
import ClickableCard from '@/components/ClickableCard.vue';
import Pedagogico from './Pedagogico.vue';
import Financeiro from './Financeiro.vue';
import Comercial from './Comercial.vue';
import CustomTabsComercialComponent from '@/components/CustomTabsComercialComponent.vue';
let DiagnosticoList = class DiagnosticoList extends Vue {
    constructor() {
        super(...arguments);
        this.tab = 'tab-pedagogico';
        this.selectedTab = 10;
    }
    get userActionsVisualizarAbaPedagogicoAllow() {
        return userActionStore.visualizarAbaPedagogicoAllow;
    }
    get userActionsVisualizarAbaFinanceiroAllow() {
        return userActionStore.visualizarAbaFinanceiroAllow;
    }
    get userActionsVisualizarAbaComercialAllow() {
        return userActionStore.visualizarAbaComercialAllow;
    }
};
DiagnosticoList = __decorate([
    Component({
        components: {
            highcharts: Chart,
            ClickableCard,
            Pedagogico,
            Financeiro,
            Comercial,
            CustomTabsComercialComponent,
        },
    })
], DiagnosticoList);
export default DiagnosticoList;
// BEATRIZ BERNARDO
